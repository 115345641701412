var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_vm._m(0),_c('div',{staticClass:"list_box"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"item"},[_vm._m(1),_c('div',{staticClass:"right_item"},[_c('div',{staticClass:"title"},[_vm._v("代帐报税")]),_vm._m(2),_c('div',{staticClass:"btn",on:{"click":_vm.toDzfwDetail}},[_vm._v("详情")])])])])]),_c('div',{staticClass:"list_box"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"item"},[_vm._m(3),_c('div',{staticClass:"right_item"},[_c('div',{staticClass:"title"},[_vm._v("数电票")]),_vm._m(4),_c('div',{staticClass:"btn",on:{"click":_vm.toSdpDetail}},[_vm._v("详情")])])])])]),_c('div',{staticClass:"list_box"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"item"},[_vm._m(5),_c('div',{staticClass:"right_item"},[_c('div',{staticClass:"title"},[_vm._v("凭证归集")]),_vm._m(6),_c('div',{staticClass:"btn",on:{"click":_vm.toDzpzDetail}},[_vm._v("详情")])])])])]),_c('div',{staticClass:"list_box"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"item"},[_vm._m(7),_c('div',{staticClass:"right_item"},[_c('div',{staticClass:"title"},[_vm._v("凭证归档")]),_vm._m(8),_c('div',{staticClass:"btn",on:{"click":_vm.toDzdaDetail}},[_vm._v("详情")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("../../assets/img/finance_1/banner.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_item"},[_c('img',{attrs:{"src":require("../../assets/img/finance_1/cover1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("专业的代记账公司，拥有丰富的财务经验和卓越的会计团队，致力于提供全方位的财务咨询服务，为企业的财务安全保驾护航。")]),_c('span',[_vm._v("产品特点")]),_c('span',[_vm._v("高效便捷：我们将为您处理所有繁琐的财务问题，让您专注于您的核心业务。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_item"},[_c('img',{attrs:{"src":require("../../assets/img/finance_1/cover2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("易联数电票是一款服务于中小微商业企业的轻量级自助开票应用，可以实现餐厅、酒店等行业顾客自助扫码开具数电发票。")]),_c('span',[_vm._v("顾客扫描开票码，录入开票信息后一键提交开票申请，")]),_c('span',[_vm._v("商户审核通过后系统将自动发送发票至邮箱。顾客申请后即可离开，无需等待，发票的开具与接收不受时间地点等因素影响，数电发票的形式也能有效避免丢失。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_item"},[_c('img',{attrs:{"src":require("../../assets/img/finance_1/cover3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("九部委最新文件种明确规定的九种数字凭证的归集。用户无需再登录多种平台或系统，只需登录数电通平台即可完成一站式凭证获取。")]),_c('span',[_vm._v("随着《关于公布电子凭证会计数据标准的通知》的出台，规范了“结构化数据文件+版式文件封装+电子签名”的电子凭证标准...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_item"},[_c('img',{attrs:{"src":require("../../assets/img/finance_1/cover4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("方便、高效的管理工具，可以轻松地存储、整理、搜索和共享各种凭证、文件和资料。")]),_c('span',[_vm._v("凭证归档产品是一种高效、安全、易用、灵活且强大的管理工具，")]),_c('span',[_vm._v("可以帮助用户轻松地管理自己的文件和资料，并且可以随时随地访问和共享这些文件和资料。")])])
}]

export { render, staticRenderFns }