<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/finance_1/banner.png" alt="" />
    </div>
    <div class="list_box">
      <div class="list">
        <div class="item">
          <div class="left_item">
            <img src="../../assets/img/finance_1/cover1.png" alt="" />
          </div>
          <div class="right_item">
            <div class="title">代帐报税</div>
            <p>
              <span
                >专业的代记账公司，拥有丰富的财务经验和卓越的会计团队，致力于提供全方位的财务咨询服务，为企业的财务安全保驾护航。</span
              >
              <span>产品特点</span>
              <span
                >高效便捷：我们将为您处理所有繁琐的财务问题，让您专注于您的核心业务。</span
              >
            </p>
             <!-- @click="toDzfwDetail" -->
            <div class="btn" @click="toDzfwDetail">详情</div>
          </div>
        </div>
      </div>
    </div>
    <div class="list_box">
      <div class="list">
        <div class="item">
          <div class="left_item">
            <img src="../../assets/img/finance_1/cover2.png" alt="" />
          </div>
          <div class="right_item">
            <div class="title">数电票</div>
            <p>
              <span
                >易联数电票是一款服务于中小微商业企业的轻量级自助开票应用，可以实现餐厅、酒店等行业顾客自助扫码开具数电发票。</span
              >
              <span>顾客扫描开票码，录入开票信息后一键提交开票申请，</span>
              <span
                >商户审核通过后系统将自动发送发票至邮箱。顾客申请后即可离开，无需等待，发票的开具与接收不受时间地点等因素影响，数电发票的形式也能有效避免丢失。</span
              >
            </p>
            <div class="btn" @click="toSdpDetail">详情</div>
          </div>
        </div>
      </div>
    </div>
    <div class="list_box">
      <div class="list">
        <div class="item">
          <div class="left_item">
            <img src="../../assets/img/finance_1/cover3.png" alt="" />
          </div>
          <div class="right_item">
            <div class="title">凭证归集</div>
            <p>
              <span
                >九部委最新文件种明确规定的九种数字凭证的归集。用户无需再登录多种平台或系统，只需登录数电通平台即可完成一站式凭证获取。</span
              >
              <span
                >随着《关于公布电子凭证会计数据标准的通知》的出台，规范了“结构化数据文件+版式文件封装+电子签名”的电子凭证标准...</span
              >
              <!-- <span>有效保障了数据来源的合法性、真实性以及数据安全性，确保了电子凭证的法律效力，标志着真正“无纸化”时代的到来。</span> -->
            </p>
            <div class="btn" @click="toDzpzDetail">详情</div>
          </div>
        </div>
      </div>
    </div>
    <div class="list_box">
      <div class="list">
        <div class="item">
          <div class="left_item">
            <img src="../../assets/img/finance_1/cover4.png" alt="" />
          </div>
          <div class="right_item">
            <div class="title">凭证归档</div>
            <p>
              <span
                >方便、高效的管理工具，可以轻松地存储、整理、搜索和共享各种凭证、文件和资料。</span
              >
              <span
                >凭证归档产品是一种高效、安全、易用、灵活且强大的管理工具，</span
              >
              <span
                >可以帮助用户轻松地管理自己的文件和资料，并且可以随时随地访问和共享这些文件和资料。</span
              >
            </p>
            <div class="btn" @click="toDzdaDetail">详情</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    toDzfwDetail() {
      this.$router.push({
        path: "/finance_1/detail_dzfw",
        query: { org: "finance_1" },
        // path: "/series",
      });
    },
    toSdpDetail() {
      this.$router.push({
        path: "/finance_1/detail_sdp",
      });
    },
    toDzpzDetail() {
      this.$router.push({
        // path: "/finance_1/detail_dzpz",
        path: "/certificateCollection",
      });
    },
    toDzdaDetail() {
      this.$router.push({
        path: "/certificateCollection",
        query: { org: "finance_1" },
      });
    },
    // subInfo() {
    //   this.$router.push({
    //     path: "/info",
    //     query: { org: "cw" },
    //   });
    // },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.list_box {
  width: 100%;
  padding: 40px;
}

.list_box:nth-child(2n-1) {
  background: #f5f5f5;
}

.list {
  width: 1200px;
  margin: 0 auto;
}

.item {
  display: flex;
}

.left_item {
  width: 430px;
  height: 230px;
}

.left_item img {
  display: block;
  width: 100%;
  height: 100%;
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.right_item {
  flex: 1;
  padding: 20px;
}

.right_item p {
  font-size: 14px;
  color: #333;
  margin: 20px 0;
}

.right_item p span {
  display: block;
  line-height: 1.5;
}

.btn {
  width: 80px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #055afe;
  font-size: 12px;
  color: #055afe;
  border-radius: 18px;
  cursor: pointer;
}
</style>
